import { Box, Grid } from '@mui/material';
import { useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import "react-multi-carousel/lib/styles.css";
import bg1 from './newbg1.jpeg';
import bg2 from './newbg2.jpeg';
import bg3 from './newbg3.jpeg';
import nexticon from './nexticon.png';
import previcon from './previcon.png';



function MainCarousel() {
  const ref = useRef(null);

  const images = [
    bg1,
    bg2,
    bg3
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const slideNext = () => {
    ref.current.slideNext();
  };

  const slidePrev = () => {
    ref.current.slidePrev();
  };
  const onSlideChanged = (e) => {
    if (e.item === 3) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(e.item);

    }
  }

  const items = images.map((item, i) => <div key={i - 1} style={{
    backgroundImage: `url(${item})`, width: '162px', height: '78px', backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat', margin: 'auto',
    borderRadius: '22px',
    border: i === currentIndex ? '3px solid #F9D649' : '2px solid rgba(255, 255, 255, 0.19)',
    filter: i === currentIndex ? 'drop-shadow(0px 0px 20px #F9D649)' : 'none'

  }}></div>)


  return (
    <Box sx={{
      backgroundImage: `url(${images[currentIndex]})`, height: { xs: '100vh' }, backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }} >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        height="100%"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: '85px' }}
        >
          <Grid xs={2}>
            <img src={previcon} style={{ cursor: 'pointer' }} onClick={slidePrev} alt='icon' />
          </Grid>
          <Grid xs={7}>
            <AliceCarousel
              items={items}
              ref={ref}
              autoPlayInterval={5000}
              animationDuration={500}
              autoPlay={true}
              infinite={true}
              mouseTracking
              disableDotsControls
              activeIndex={currentIndex}
              dotsDisabled={true}
              disableButtonsControls
              onSlideChanged={onSlideChanged}
              responsive={
                {
                  520: {
                    items: 1
                  },
                  680: {
                    items: 2,
                  },
                  1024: {
                    items: 3
                  },
                }}
            />
          </Grid>
          <Grid xs={2} style={{ height: '100%' }}>
            <img src={nexticon} style={{ cursor: 'pointer' }} onClick={slideNext} alt='icon' />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MainCarousel;
