import { Box, Button, ButtonGroup, Grid, Typography } from '@mui/material';
import team1 from './defaultTeam.png';
import mikeTeam from './mikeTeam.png';
import kimnTeam from './kimnTeam.png';
import girikTeam from './girikTeam.png';
import dinoTeam from './dinoTeam.png';
import chadTeam from './chadTeam.png';


import insta_mini from './insta_mini.png';
import twitter_mini from './twitter_mini.png';
import { MetaPageHeaderText } from './comp';

function MainVideoContainer() {

  const team = [
    {
      image: team1,
      name: "Girik Rajwani",
      des: "Founder & CEO "
    },
    {
      image: team1,
      name: "Dino Eleftheriadis",
      des: "Co-founder & COO"
    },
    {
      image: mikeTeam,
      name: "MIKE EILERTSEN",
      des: "CTO"
    },
    {
      image: kimnTeam,
      name: "KIMON SARMIS",
      des: "Creative Director"
    },
    {
      image: team1,
      name: "Chad Favish",
      des: "Business Development"
    },
    {
      image: team1,
      name: "Nicholas Schreiber",
      des: "Marketing"
    },
  ]

  return (
    <Box id="Team" sx={{
      backgroundColor: "#000000",
      paddingTop: 20
    }} >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{padding: '0px 0px 16px'}}
      >
        <Grid item={'auto'}>
        <MetaPageHeaderText text="Our Team" />
        </Grid>
      </Grid>
      <Box id="Membership" sx={{
      backgroundColor: "#000000",
      height: '100%',
      pt: 8,
      pb: {xs:2, sm:3}
    }}>
      <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'0px 5vw',sm:'0px 7vw',lg:'0px 10vw'},  }}
      >
        <Grid xs={12} md={4}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 10px', sm: '60px 10px'} }}
        >
               <Grid xs={12} md={12}>
               <img src={girikTeam} style={{width: '267px', height: '273px', borderRadius: "25px" }} />
               </Grid>

               <Grid xs={12} md={12}>
               <Grid item={'auto'}>
                  <Typography sx={{ color: '#FEDB24', fontSize: {xs:'14px', sm: '20px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                        mt: {xs:'30px', sm: '50px'},
                        textAlign: 'center', maxWidth: '100%',
                        textTransform: 'uppercase'

                  }}>
                  Girik Rajwani 
                  </Typography>
                    </Grid>
                    <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'15px', sm: '20px'},
                        textAlign: 'center', maxWidth: '100%'
                  }}>
                  Founder <br></br>CEO 
                  </Typography>
                    </Grid>
               </Grid>

            </Grid>
        </Grid>
        <Grid xs={12} md={4}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 10px', sm: '60px 10px'} }}
        >
               <Grid xs={12} md={12}>
               <img src={dinoTeam} style={{  width: '267px', height: '273px', borderRadius: "25px" }} />
               </Grid>

               <Grid xs={12} md={12}>
               <Grid item={'auto'}>
                  <Typography sx={{ color: '#FEDB24', fontSize: {xs:'14px', sm: '20px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                        mt: {xs:'30px', sm: '50px'},
                        textAlign: 'center', maxWidth: '100%',
                        textTransform: 'uppercase'

                  }}>
                  Dino Eleftheriadis
                  </Typography>
                    </Grid>
                    <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'15px', sm: '20px'},
                        textAlign: 'center', maxWidth: '100%'
                  }}>
                    Co-Founder <br></br>COO 
                  </Typography>
                    </Grid>
               </Grid>

            </Grid>
        </Grid>
        <Grid xs={12} md={4}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 10px', sm: '60px 10px'} }}
        >
               <Grid xs={12} md={12}>
               <img src={mikeTeam} style={{ width: '267px', height: '273px', borderRadius: "25px" }} />
               </Grid>

               <Grid xs={12} md={12}>
               <Grid item={'auto'}>
                  <Typography sx={{ color: '#FEDB24', fontSize: {xs:'14px', sm: '20px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                        mt: {xs:'30px', sm: '50px'},
                        textAlign: 'center', maxWidth: '100%',
                        textTransform: 'uppercase'

                  }}>
                  MIKE EILERTSEN
                  </Typography>
                    </Grid>
                    <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'15px', sm: '20px'},
                        textAlign: 'center', maxWidth: '100%'
                  }}>
                    <br></br>
                   CTO
                  </Typography>
                    </Grid>
               </Grid>

            </Grid>
        </Grid>

      </Grid>
      <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'0px 5vw',sm:'0px 7vw',lg:'0px 10vw'},  }}
      >
        <Grid xs={12} md={4}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 10px', sm: '60px 10px'} }}
        >
               <Grid xs={12} md={12}>
               <img src={kimnTeam} style={{width: '267px', height: '273px', borderRadius: "25px" }} />
               </Grid>

               <Grid xs={12} md={12}>
               <Grid item={'auto'}>
                  <Typography sx={{ color: '#FEDB24', fontSize: {xs:'14px', sm: '20px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                        mt: {xs:'30px', sm: '50px'},
                        textAlign: 'center', maxWidth: '100%',
                        textTransform: 'uppercase'

                  }}>
                  KIMON SARMIS
                  </Typography>
                    </Grid>
                    <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'15px', sm: '20px'},
                        textAlign: 'center', maxWidth: '100%'
                  }}>
                  Creative Director 
                  </Typography>
                    </Grid>
               </Grid>

            </Grid>
        </Grid>
        <Grid xs={12} md={4}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 10px', sm: '60px 10px'} }}
        >
               <Grid xs={12} md={12}>
               <img src={chadTeam} style={{  width: '267px', height: '273px', borderRadius: "25px" }} />
               </Grid>

               <Grid xs={12} md={12}>
               <Grid item={'auto'}>
                  <Typography sx={{ color: '#FEDB24', fontSize: {xs:'14px', sm: '20px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                        mt: {xs:'30px', sm: '50px'},
                        textAlign: 'center', maxWidth: '100%',
                        textTransform: 'uppercase'

                  }}>
                  Chad Favish 
                  </Typography>
                    </Grid>
                    <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'15px', sm: '20px'},
                        textAlign: 'center', maxWidth: '100%'
                  }}>
                    Business Development 
                  </Typography>
                    </Grid>
               </Grid>

            </Grid>
        </Grid>
        <Grid xs={12} md={4}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 10px', sm: '60px 10px'} }}
        >
               <Grid xs={12} md={12}>
               <img src={team1} style={{ width: '267px', height: '273px', borderRadius: "25px" }} />
               </Grid>

               <Grid xs={12} md={12}>
               <Grid item={'auto'}>
                  <Typography sx={{ color: '#FEDB24', fontSize: {xs:'14px', sm: '20px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                        mt: {xs:'30px', sm: '50px'},
                        textAlign: 'center', maxWidth: '100%',
                        textTransform: 'uppercase'

                  }}>
                  Nicholas Schreiber
                  </Typography>
                    </Grid>
                    <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'15px', sm: '20px'},
                        textAlign: 'center', maxWidth: '100%'
                  }}>
                    Marketing
                  </Typography>
                    </Grid>
               </Grid>

            </Grid>
        </Grid>

      </Grid>


    </Box>
     
    </Box>
  );
}

export default MainVideoContainer;
