import { Box, Button, ButtonGroup, Container, Grid, Typography } from '@mui/material';
import { width } from '@mui/system';
import AliceCarousel from 'react-alice-carousel';
import { FaChevronCircleDown } from 'react-icons/fa';

import aboutPic from './aboutPic.png';


import { MetaBlackBtn, MetaPageHeaderDetail, MetaPageHeaderText, MetaTextLabel } from './comp';
import about1 from './about1.png';
import about2 from './about2.png';





import {Carousel} from "react-responsive-carousel";


function AboutPage() {
 

  return (
  

    <Box id="Team" sx={{
      backgroundColor: "#000000",
      paddingTop: 5
    }} >
      <Box id="MarketPlace" sx={{
      backgroundColor: "#000000",
      height: '100%',
      width:'100%',
      pt: 8,
      pb: {xs:2, sm:3}
    }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'0px 5vw',sm:'0px 7vw',lg:'0px 10vw'},  }}
      >
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 0px', sm: '60px 0px'} }}
        >
          <Grid xs={12} md={12}>

          <Typography sx={{ color: '#FEDB24', fontSize: {xs:'25px', md: '32px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: '0.11em',
            textTransform: 'uppercase'
          }}>
          About
          </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={12} >
        <Grid
        container
        direction="column"
        justifyContent="Center"
        alignItems="Center"
        sx={{padding: {xs:'0px 5vw',sm:'0px 7vw',lg:'0px 10vw'},  }}
        >
       
            <Grid item={'auto'}>
          <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 300,
                mb: {xs:'15px', sm: '20px'},
                 textAlign: 'center', maxWidth: '100%'
          }}>
            Bringing out of this world experiences to thrill seekers. The Meta Fushko Arena bends the laws of reality in an all encompassing immersive gaming experience.          </Typography>
            </Grid>
     
        </Grid> 
        </Grid>
        <Grid xs={12} md={12}>
            <img src={aboutPic} style={{ width: '100%', height: '100%', borderRadius: "25px" }} />
        </Grid>

      </Grid>

    

    </Box>

    <Box id="Games" sx={{
      backgroundColor: "#000000",
      height: '100%',
      pt: 8,
      pb: {xs:2, sm:3}
    }}>
     
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'0px 5vw',sm:'0px 7vw',lg:'0px 12vw'},  }}
      >
        <Grid xs={12} sx={{ height:{xs:"20px", md: '40px'}}}></Grid>
        <Grid xs={12} md={6} sx={{display:{xs: 'inherit' ,md: 'none'}}} >
          <img src={about1} style={{width:'100%' ,  borderRadius: "25px"}} />
        </Grid>
        <Grid xs={12} md={6}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 0px', md:'60px 0px'}}}
          >
            
            <Grid item={'auto'}>
          <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 400,
                mt: {xs:'30px', sm: '50px'},
                pr:{xs:'0px', sm: '20px'},
                 textAlign: 'left',
          }}>
It started with a dream. Like most great stories and game-changing inventions, Metafushko began with a crazy idea shared among even crazier people. What if we could redefine the world of gaming and how players and gamblers interact with each other? How could we build a platform that brings together hundreds of unique tribes led by their famous community heroes, influencers and artists?
It started by recruiting the right team to turn the daydream into a working reality. The squad grew to become a talented, cross-functional mix of gamers, gamblers, coders and developers, but they all had one thing in common. They wanted more from the metaverse, a better experience that weaved all their favorite virtual worlds into one seamless digital adventure. So, they built it from scratch, kickstarting the creation of the Marketplace, Fushko tokens, and the rest of the blockchain-built brilliance.
          
          </Typography>
            </Grid>
            
              
          </Grid> 
        </Grid>

        <Grid xs={12} md={6} sx={{display:{xs: 'none' ,md: 'inherit'}}}  >
          <img src={about1} style={{ width:'90%' ,borderRadius: "25px" }} />
        </Grid>
        <Grid xs={12} sx={{ height:{xs:"20px", md: '40px'}}}></Grid>

        <Grid xs={12} md={6}>
            <img src={about2} style={{ width: '80%', height: '100%', borderRadius: "25px" }} />
        </Grid>
        <Grid xs={12} md={6} >
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 0px', sm: '60px 0px'} }}
        >
            
            <Grid item={'auto'}>
          <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 400,
                mt: {xs:'30px', sm: '50px'},
                pl:{xs:'0px', sm: '20px'},
                 textAlign: 'left'
          }}>
But that was just the start. The idea also hinged on a brilliant, never-seen-before concept of peer betting with NFTs and collectibles in a sensational new virtual playground filled with casino games and miniverse games that changes according to how you play and where you explore. Your traits and collectibles define your access: How you play and earn rewards influences your Metafushko world.
That’s right, thanks to the unique play-to-earn economy, you get rewarded as you rise to the top of the ranks within the Metafushko ecosystem. It’s a slick system with FSHK tokens as the in-game currency, and it is bristling with smart functionalities. Case in point: a percentage of the player’s spend gets redistributed within the greater Metafushko economy. So, for example, if you choose to spend on an Aria trait, 30% goes back to the Aria community treasury, and you rise higher through the ranks thanks to the contribution points. Brilliant, right?
                        </Typography>
            </Grid>
    
        </Grid> 
        </Grid>
        <Grid xs={12} md={6} sx={{display:{xs: 'inherit' ,md: 'none'}}} >
          <img src={about2} style={{width: '100%', borderRadius: "25px"}} />
        </Grid>
        <Grid xs={12} sx={{ height:{xs:"20px", md: '40px'}}}></Grid>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 30px', sm: '60px 0px'} }}
        >
          <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 400,
                mt: {xs:'30px', sm: '50px'},
                pl:{xs:'0px', sm: '20px'},
                 textAlign: 'left'
          }}>
So, the daydream has taken shape, and now this virtual world is powered by the latest trends and collaborations with the smartest, slickest talents in art, culture, tech, music and gaming. Every unique community has its own miniverse to influence and grow.
The best news? The Metafushko revolution is just beginning. In short, it’s the right bet to back in the race to conquer the metaverse. It opens iGaming up to a whole new market of younger gamers, redefining the gambling experience in our brave new virtual worlds.
The bottom line: Don’t miss out on the future. It’s time for you to join the FUSHKO Nation. </Typography>
        </Grid>
  
      </Grid>
      <Grid xs={12} sx={{ height:{xs:"20px", md: '40px'}}}></Grid>
      <Grid xs={12} md={6} >
     
      </Grid>
 
      </Box>
    </Box>
  );
}

export default AboutPage;
