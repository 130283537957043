import { Box, Button, ButtonGroup, Container, Grid, Typography } from '@mui/material';
import { width } from '@mui/system';
import AliceCarousel from 'react-alice-carousel';
import { FaChevronCircleDown } from 'react-icons/fa';
import g1 from './game1.png';
import g2 from './game2.png';
import marketheader from './marketheader.png';
import c2 from './c5.png';
import market1 from './market1.png';
import market2 from './market2.png';
import market3 from './market3.png';

import { MetaBlackBtn, MetaPageHeaderDetail, MetaPageHeaderText, MetaTextLabel } from './comp';
import gameMarket1 from './gameMarket1.png';
import gameMarket2 from './gameMarket2.png';
import gameMarket3 from './gameMarket3.png';





import {Carousel} from "react-responsive-carousel";


function MarketPlacePage() {
 

  return (
  

    <Box id="Team" sx={{
      backgroundColor: "#000000",
      paddingTop: 5
    }} >
      <Box id="MarketPlace" sx={{
      backgroundColor: "#000000",
      height: '100%',
      width:'100%',
      pt: 8,
      pb: {xs:2, sm:3}
    }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'0px 5vw',sm:'0px 7vw',lg:'0px 10vw'},  }}
      >
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 0px', sm: '60px 0px'} }}
        >
          <Grid xs={12} md={12}>

          <Typography sx={{ color: '#FEDB24', fontSize: {xs:'25px', md: '32px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: '0.11em',
            textTransform: 'uppercase'
          }}>
          YOUR ULTIMATE MARKETPLACE
          </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={12}>
            <img src={marketheader} style={{ width: '100%', height: '100%', borderRadius: "25px" }} />
        </Grid>

      </Grid>

      <Grid xs={12} md={6} >
        <Grid
        container
        direction="column"
        justifyContent="Center"
        alignItems="Center"
        sx={{padding: {xs:'0px 5vw',sm:'0px 7vw',lg:'0px 10vw'},  }}
        >
        <Grid item={'auto'}>
          <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 700,
                mt: {xs:'30px', sm: '50px'},
                 textAlign: 'left', maxWidth: '100%'
          }}>
          THIS IS YOUR NEW IMMERSIVE ECONOMY
          </Typography>
            </Grid>
            <Grid item={'auto'}>
          <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 300,
                mt: {xs:'15px', sm: '20px'},
                 textAlign: 'left', maxWidth: '100%'
          }}>
           A digital economy that is designed to reward players through levelling up their digital assets. A selection of  bespoke collections, each with its own flavors, identities and communities.  Every single digital asset holds a value according to rarity and perks, and they are all tradable with other players. Trade them wisely and you’ll be able flip them for a profit. Here are some of the assets:
          </Typography>
            </Grid>
     
        </Grid> 
        </Grid>

    </Box>
    <Box id="Membership" sx={{
      backgroundColor: "#000000",
      height: '100%',
      pt: 8,
      pb: {xs:2, sm:3}
    }}>
      <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'0px 5vw',sm:'0px 7vw',lg:'0px 10vw'},  }}
      >
        <Grid xs={12} md={4}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 10px', sm: '60px 10px'} }}
        >
               <Grid xs={12} md={12}>
               <img src={market1} style={{ width: '100%', height: '100%', borderRadius: "25px" }} />
               </Grid>

               <Grid xs={12} md={12}>
               <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                        mt: {xs:'30px', sm: '50px'},
                        textAlign: 'center', maxWidth: '100%',
                        textTransform: 'uppercase'

                  }}>
                  Membership pass
                  </Typography>
                    </Grid>
                    <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'15px', sm: '20px'},
                        textAlign: 'center', maxWidth: '100%'
                  }}>
                  This is your key to the MetaFushko community. Gain access to higher jackpots, exclusive games and your own community miniverse. 
                  </Typography>
                    </Grid>
               </Grid>

            </Grid>
        </Grid>
        <Grid xs={12} md={4}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 10px', sm: '60px 10px'} }}
        >
               <Grid xs={12} md={12}>
               <img src={market2} style={{ width: '100%', height: '100%', borderRadius: "25px" }} />
               </Grid>

               <Grid xs={12} md={12}>
               <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                        mt: {xs:'30px', sm: '50px'},
                        textAlign: 'center', maxWidth: '100%',
                        textTransform: 'uppercase'

                  }}>
                  SIGNATURE Traits 
                  </Typography>
                    </Grid>
                    <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'15px', sm: '20px'},
                        textAlign: 'center', maxWidth: '100%'
                  }}>
                  Owning these traits define how your character looks. Buy or earn them and you’ll get access to exclusive games and added benefits for that trait class. 
                  </Typography>
                    </Grid>
               </Grid>

            </Grid>
        </Grid>
        <Grid xs={12} md={4}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 10px', sm: '60px 10px'} }}
        >
               <Grid xs={12} md={12}>
               <img src={market3} style={{ width: '100%', height: '100%', borderRadius: "25px" }} />
               </Grid>

               <Grid xs={12} md={12}>
               <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                        mt: {xs:'30px', sm: '50px'},
                        textAlign: 'center', maxWidth: '100%',
                        textTransform: 'uppercase'

                  }}>
                  POTENT UPGRADES
                  </Typography>
                    </Grid>
                    <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'15px', sm: '20px'},
                        textAlign: 'center', maxWidth: '100%'
                  }}>
                   Gain a major advantage over other players through earning or buying these potent upgrades. Use them wisely, and you’ll unlock huge rewards.
                  </Typography>
                    </Grid>
               </Grid>

            </Grid>
        </Grid>

      </Grid>


    </Box>
    <Box id="Games" sx={{
      backgroundColor: "#000000",
      height: '100%',
      pt: 8,
      pb: {xs:2, sm:3}
    }}>
     
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'0px 5vw',sm:'0px 7vw',lg:'0px 12vw'},  }}
      >
        <Grid xs={12} sx={{ height:{xs:"20px", md: '40px'}}}></Grid>
        <Grid xs={12} md={6} sx={{display:{xs: 'inherit' ,md: 'none'}}} >
          <img src={gameMarket3} style={{width: '100%', height: '100%', borderRadius: "25px"}} />
        </Grid>
        <Grid xs={12} md={6}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 0px', md:'60px 0px'}}}
          >
            <Grid item={'auto'}>
            <Typography sx={{ color: '#FEDB24', fontSize: {xs:'23px', md: '25px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: '0.11em',
            textTransform: 'uppercase'
          }}>
          Mystery Box
          </Typography>
            </Grid>
            <Grid item={'auto'}>
          <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 400,
                mt: {xs:'30px', sm: '50px'},
                padding: '0px 5vw', textAlign: 'center', maxWidth: '365px'
          }}>
          You can unbox all kinds of epic digital assets, including Potent Upgrades and Signature Traits. If you’re really lucky, you may just bag some of the rarest items too. 
          
          </Typography>
            </Grid>
            
              
          </Grid> 
        </Grid>

        <Grid xs={12} md={6} sx={{display:{xs: 'none' ,md: 'inherit'}}}  >
          <img src={gameMarket3} style={{width: '100%', height: '100%', borderRadius: "25px" }} />
        </Grid>
        <Grid xs={12} sx={{ height:{xs:"20px", md: '40px'}}}></Grid>

        <Grid xs={12} md={6}>
            <img src={gameMarket1} style={{ width: '100%', height: '100%', borderRadius: "25px" }} />
        </Grid>
        <Grid xs={12} md={6} >
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 0px', sm: '60px 0px'} }}
        >
            <Grid item={'auto'}>
          <Typography sx={{ color: '#FEDB24', fontSize: {xs:'23px', md: '25px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: '0.11em',
            textTransform: 'uppercase'
          }}>
          RARE COLLECTIBLES
          </Typography>
            </Grid>
            <Grid item={'auto'}>
          <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 400,
                mt: {xs:'30px', sm: '50px'},
                padding: '0px 5vw', textAlign: 'center', maxWidth: '365px'
          }}>
              When it comes to rarity, you can beat unique, limited-edition NFT artworks crafted by some of the best digital artists in the metaverse. In some cases, you’ll also get physical artworks delivered to your door. 
                        </Typography>
            </Grid>
    
        </Grid> 
        </Grid>
        <Grid xs={12} md={6} sx={{display:{xs: 'inherit' ,md: 'none'}}} >
          <img src={gameMarket2} style={{width: '100%', height: '100%', borderRadius: "25px"}} />
        </Grid>
        <Grid xs={12} sx={{ height:{xs:"20px", md: '40px'}}}></Grid>
        <Grid xs={12} md={6}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 0px', md:'60px 0px'}}}
          >
            <Grid item={'auto'}>
            <Typography sx={{ color: '#FEDB24', fontSize: {xs:'23px', md: '25px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: '0.11em',
            textTransform: 'uppercase'
          }}>
          LAND OWNERSHIP
          </Typography>
            </Grid>
            <Grid item={'auto'}>
          <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 400,
                mt: {xs:'30px', sm: '50px'},
                padding: '0px 5vw', textAlign: 'center', maxWidth: '365px'
          }}>
             As a community member, you can own a plot of digital land within each Miniverse. Breed your clan and earn the following rewards: royalties, more voting rights, and social status.           
             </Typography>
            </Grid>
            
              
          </Grid> 
        </Grid>
        <Grid xs={12} md={6} sx={{display:{xs: 'none' ,md: 'inherit'}}} >
          <img src={gameMarket2} style={{width: '100%', height: '100%', borderRadius: "25px" }} />
        </Grid>
      </Grid>
      <Grid xs={12} sx={{ height:{xs:"20px", md: '40px'}}}></Grid>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: '45px 8vw', background: 'linear-gradient(270deg, #F9D649 -30.87%, #915700 100%)' }}
        rowGap={4}
      >
          <MetaPageHeaderText text='START EARNING' color='#000000' weight={900}/>
          <MetaPageHeaderDetail text='The lifeblood of the MetaFushko Marketplace and Metaverse: FSHK tokens. Players can use FSHK tokens to bet and play against opponents on our trusted platform as well as purchase some of the rarest digital collectibles. ' color='#000000'/>
          <MetaBlackBtn text='BUY FSHK TOKEN' />
        </Grid>
      </Box>
    </Box>
  );
}

export default MarketPlacePage;
