import './App.css';
// import ComContainer from './components/ComContainer';
import ComNewContainer from './components/ComNewContainer';
import GamesNewContainer from './components/GamesNewContainer';
import MainCarousel from './components/MainCarousel';
import MainVideoContainer from './components/MainVideoContainer';
import MarketPlaceContainer from './components/MarketPlaceContainer';



function App() {
  return (
    <>
      <MainCarousel />
      <MainVideoContainer />
      {/* <ComContainer /> */}
      <MarketPlaceContainer />
      <GamesNewContainer />
      <ComNewContainer />
    </>
  );
}

export default App;
