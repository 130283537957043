import { Box, Button, ButtonGroup, Grid, InputBase, TextField, Typography, withStyles } from '@mui/material';
import { width } from '@mui/system';
import { FaChevronCircleDown } from 'react-icons/fa';
import m1 from './m1.png';
import m2 from './m2.png';
import makeStyles from '@emotion/styled';
import twitter from './twitter 1.png';
import discord from './discord 1.png';
import instagram from './instagram 1.png';
import email from './email 1.png';
import logo from './footer_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MetaBlackBtn } from './comp';
import { faDiscord, faTwitter,faInstagram } from '@fortawesome/free-brands-svg-icons'
import './socials.css'; // Tell webpack that Button.js uses these styles
 




const useStyles = makeStyles({
  input: {
    color: '#F9D649',
    fontSize: '24px',
    fontFamily: 'Orbitron',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: '0.5em',
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  ullia:{
  position: 'relative',
  display: 'block',
  width: '60px',
  height: '60px',
  textAlign: 'center',
  lineHeight: '63px',
  background: '#333',
  borderRadius: '50%',
  fontSize: '30px',
  color: '#666',
  transition: '.5s',
  '&::before': {
    content: '',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    background: '#ffee10',
    transition: '.5s',
    transform: 'scale(.9)',
    
},
'&:hover': {
  color: '#ffee10',
  boxShadow: '0 0 5px #ffee10',
  textShadow: '0 0 5px #ffee10'
}
  }
  
});

// const BootstrapInput = withStyles((theme) =>
//   createStyles({
//     input: {
//       borderRadius: 6,
//       border: '1px solid #FFF',
//       backgroundColor: "transparent",
//       color: 'red',
//       fontSize: 16,
//       padding: '10px 12px',
//       '&:focus': {
//         borderColor: 'none',
//       },
//     },
//   }),
// )(InputBase);

function ContactusContainer() {
  const classes = useStyles();
  
  let iconStyles = { fontSize: "40px", color:'#FFFFFF', margin: 0, backgroundColor: 'black', borderRadius:'50px', marginTop: '-20px'};
  return (
    <Box sx={{
      backgroundColor: "#000000",
      height: '100%',
      paddingTop: 0,
    }}>
        <Grid container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: '0px'}}
      >
        <Grid xs={'12'} >
        <Typography sx={{ color: '#F9D649', fontSize: {xs:'23px',md:'25px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing:'0.11em',
            textTransform: 'uppercase'
          }}>
         Join the fushkonation
          </Typography>
        </Grid>
        <Grid xs={'12'} >
        <Typography sx={{ color: '#FFFFFF', fontSize: '14px',fontFamily: 'Orbitron',
            fontStyle: 'normal',
              fontWeight: 400,
              mt: { xs: 2, md: 2 },
          }}>
        Keep up to date with the latest releases and be the first to get exclusive offers
          </Typography>
        </Grid>
        <Grid xs={12}>
        <Box sx={{
                background: '#2D2D2D',
            borderRadius: '60px', display: 'flex',
            // paddingLeft: {xs:'10px', md: '20px'},
            width: { xs: "100%", sm: "480px" },
                marginTop: "35px"
              }}>
                <input
                  placeholder='Enter your email'
                  style={{
                    backgroundColor: 'transparent',
                    borderRadius: '60px',
                    border: '#2D2D2D',
                  color: '#9B9B9B',
                    fontWeight: 700,
                    flex: 1,
                  fontSize: '14px',
                  height: '37px',
                    margin: "auto 10px",
                    "&:focus": {
                      outline: 'none'
                  }
                  
              }} />
            <MetaBlackBtn text='SUBSCRIBE' xsmall={true} />
            </Box>
             </Grid>
        <Grid xs={12} md={'auto'}>
        
              </Grid>
        <Grid
        container
        direction="row"
        justifyContent="center"
          alignItems="center"
          sx={{mt: {xs:4 ,md:8},mb: {xs:5 ,md:5}}}
        >
          

          <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css" integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/" crossorigin="anonymous"/>
          <Grid xs={2} md={1}>
          <li className='ulli ullia'  >
                <a href="#"  className='ullia'  >
                <i class="fas fa-envelope"></i>                
                </a>
              </li>

          </Grid>
          <Grid xs={2} md={1}>
          <li className='ulli ullia'  >
                <a href="https://twitter.com/metafushko?s=11&t=102dxl8V1dPRKAYVN9lOqg"  target="_blank"  className='ullia'  >
                  <i class="fab fa-twitter"></i>
                </a>
              </li>

          </Grid>
          <Grid xs={2} md={1}>
          <li className='ulli ullia'  >
                <a href="https://instagram.com/metafushko?igshid=YmMyMTA2M2Y="  target="_blank" className='ullia'  >
                  <i class="fab fa-instagram"></i>
                </a>
              </li>

          </Grid>
          <Grid xs={2} md={1}>
          <li className='ulli ullia'  >
                <a href="https://discord.gg/dYGvwKhp8P"  target="_blank"  className='ullia'  >
                  <i class="fab fa-discord"></i>
                </a>
              </li>

          </Grid>
           
        </Grid>

       
      
        
        <Grid
        container
        direction="row"
        justifyContent="center"
          alignItems="center"
          sx={{mt: {xs:3 ,md:3},mb: {xs:4 ,md:5}}}
        >
          <img src={logo}
            style={{cursor: 'pointer'}}
            onClick={() => {
            window.location.href = "/";
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}  />
        </Grid>
       
        </Grid>
      
      </Box>
  );
}



export default ContactusContainer;
