import { Box } from '@mui/material';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import AboutPage from './components/AboutPage';
import CommunityPage from './components/CommunityPage';
import ContactusContainer from './components/ContactusContainer';
import GamesPage from './components/GamesPage';
import MarketPlacePage from './components/MaketPlacePage';
import Support from './components/Support';
import TeamContainer from './components/TeamContainer';
import Home from './Home';
import ScrollToTop from './ScrollToTop';

import { useEffect } from 'react';
import WebFont from 'webfontloader';



function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter', 'Orbitron']
      }
    });
  }, []);
  return (
    <div className="App">
      <Box sx={{ width: '100%', margin: 0 }} >
        <Router>
          <ScrollToTop />
          <Routes>
            <Route exact path='/' element={< Home />}></Route>
            <Route exact path='/team' element={< TeamContainer />}></Route>
            <Route exact path='/support' element={< Support />}></Route>
            <Route exact path='/marketplace' element={< MarketPlacePage />}></Route>
            <Route exact path='/community' element={< CommunityPage />}></Route>
            <Route exact path='/games' element={< GamesPage />}></Route>
            <Route exact path='/about' element={< AboutPage />}></Route>

          </Routes>
          <ContactusContainer />
        </Router>
      </Box>

    </div>
  );
}

export default App;
