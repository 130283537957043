import { Box, Grid, Typography } from '@mui/material';
import gamecat1 from './gamescat1.png';
import gamecat2 from './gamescat2.png';
import gamecat3 from './gamescat3.png';
import gamecat4 from './gamescat4.png';

function GamesNewContainer() {
  return (
    <Box id="Team" sx={{
      backgroundColor: "#000000",
      paddingTop: 5
    }} >
      <Box id="MarketPlace" sx={{
        backgroundColor: "#000000",
        height: '100%',
        width: '100%',
        pt: 0,
        pb: { xs: 0, sm: 0 }
      }}>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: { xs: '30px 0px 0px 0px', sm: '20px 0px 0px 0px' } }}
        >
          <Grid xs={12} md={12}>

            <Typography sx={{
              color: '#FEDB24', fontSize: { xs: '25px', md: '32px' }, fontFamily: 'Orbitron',
              fontStyle: 'normal',
              fontWeight: 700,
              letterSpacing: '0.11em',
              textTransform: 'uppercase'
            }}>
              METAFUSHKO RedefinES Gaming
            </Typography>
          </Grid>
          <Grid item={'auto'} >
            <Typography sx={{
              color: '#FFF', fontSize: { xs: '14px', sm: '17px' }, fontFamily: 'Orbitron', px: "50px",
              fontStyle: 'normal',
              fontWeight: 400,
              mt: { xs: '15px', sm: '20px' },
              textAlign: 'center', maxWidth: '800px'
            }}>
              It’s a revolutionary and rewarding fusion of gaming and strategy. You can play and win by
              investing in and earning digital assets.
            </Typography>
          </Grid>
        </Grid>

      </Box>
      <Box id="Membership" sx={{
        backgroundColor: "#000000",
        height: '100%',
        pt: 0,
        pb: { xs: 2, sm: 3 }
      }}>
        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: { xs: '0px 5vw', sm: '0px 7vw', lg: '0px 12vw' }, }}
        >
          <Grid xs={12} md={6}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ padding: { xs: '30px 30px', sm: '60px 30px' } }}
            >
              <Grid xs={12} md={12}>
                <img src={gamecat1} style={{ width: '100%', height: '100%', borderRadius: "25px" }} alt='icon' />
              </Grid>

              <Grid xs={12} md={12}>
                <Grid item={'auto'}>
                  <Typography sx={{
                    color: '#FEDB24', fontSize: { xs: '14px', sm: '20px' }, fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    mt: { xs: '5px', sm: '5px' },
                    textAlign: 'center', maxWidth: '100%',
                    textTransform: 'uppercase'

                  }}>
                    ENTER THE GRAND PRIZE
                  </Typography>
                </Grid>
                <Grid item={'auto'}>
                  <Typography sx={{
                    color: '#FFF', fontSize: { xs: '14px', sm: '14px' }, fontFamily: 'Orbitron', px: "50px",
                    fontStyle: 'normal',
                    fontWeight: 400,
                    mt: { xs: '5px', sm: '5px' },
                    textAlign: 'Center', maxWidth: '100%',
                  }}>
                    Our Grand Prize games pool all contributions from players into a prize for significant rewards.
                    Play the first game here for a chance to win the inaugural prize!
                  </Typography>
                </Grid>

              </Grid>

            </Grid>
          </Grid>
          <Grid xs={12} md={6}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ padding: { xs: '30px 30px', sm: '60px 30px' } }}
            >
              <Grid xs={12} md={12}>
                <img src={gamecat2} style={{ width: '100%', height: '100%', borderRadius: "25px" }} alt='CRUSH RIVALS IN PVP Gulag' />
              </Grid>

              <Grid xs={12} md={12}>
                <Grid item={'auto'}>
                  <Typography sx={{
                    color: '#FEDB24', fontSize: { xs: '14px', sm: '20px' }, fontFamily: 'Orbitron', px: "50px",
                    fontStyle: 'normal',
                    fontWeight: 700,
                    mt: { xs: '5px', sm: '5px' },
                    textAlign: 'center', maxWidth: '100%',
                    textTransform: 'uppercase'

                  }}>
                    CRUSH RIVALS IN PVP GULAG
                  </Typography>
                </Grid>
                <Grid item={'auto'}>
                  <Typography sx={{
                    color: '#FFF', fontSize: { xs: '14px', sm: '14px' }, fontFamily: 'Orbitron', px: "50px",
                    fontStyle: 'normal',
                    fontWeight: 400,
                    mt: { xs: '5px', sm: '5px' },
                    textAlign: 'Center', maxWidth: '100%',

                  }}>
                    There’s nowhere to hide in this fast-paced, quick-win gaming battle where users can invest their
                    digital assets in a player vs. player shooting game. The competition is intense, so stay sharp!
                  </Typography>
                </Grid>

              </Grid>

            </Grid>
          </Grid>


        </Grid>

        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: { xs: '0px 5vw', sm: '0px 7vw', lg: '0px 12vw' }, }}
        >
          <Grid xs={12} md={6}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ padding: { xs: '30px 30px', sm: '60px 30px' } }}
            >
              <Grid xs={12} md={12}>
                <img src={gamecat3} style={{ width: '100%', height: '100%', borderRadius: "25px" }} alt='Feeling Lucky' />
              </Grid>

              <Grid xs={12} md={12}>
                <Grid item={'auto'}>
                  <Typography sx={{
                    color: '#FEDB24', fontSize: { xs: '14px', sm: '20px' }, fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    mt: { xs: '5px', sm: '5px' },
                    textAlign: 'center', maxWidth: '100%',
                    textTransform: 'uppercase'

                  }}>
                    PLAY TO WIN
                  </Typography>
                </Grid>
                <Grid item={'auto'}>
                  <Typography sx={{
                    color: '#FFF', fontSize: { xs: '14px', sm: '14px' }, fontFamily: 'Orbitron', px: "50px",
                    fontStyle: 'normal',
                    fontWeight: 400,
                    mt: { xs: '5px', sm: '5px' },
                    textAlign: 'Center', maxWidth: '100%',

                  }}>
                    Our exciting selection of games offers both enjoyment and rewards. Join in on the virtual fun
                    and have a chance to win in-game assets that enhance your character and increase its
                    value.
                  </Typography>
                </Grid>

              </Grid>

            </Grid>
          </Grid>
          <Grid xs={12} md={6}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ padding: { xs: '30px 30px', sm: '60px 30px' } }}
            >
              <Grid xs={12} md={12}>
                <img src={gamecat4} style={{ width: '100%', height: '100%', borderRadius: "25px" }} alt='UNLOCK miniverse games' />
              </Grid>

              <Grid xs={12} md={12}
              >
                <Grid item={'auto'}>
                  <Typography sx={{
                    color: '#FEDB24', fontSize: { xs: '14px', sm: '20px' }, fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    mt: { xs: '5px', sm: '5px' },
                    textAlign: 'center', maxWidth: '100%',
                    textTransform: 'uppercase'

                  }}>
                    UNLOCK MINIVERSE GAMES
                  </Typography>
                </Grid>
                <Grid item={'auto'}>
                  <Typography sx={{
                    color: '#FFF', fontSize: { xs: '14px', sm: '14px' }, fontFamily: 'Orbitron', px: "50px",
                    fontStyle: 'normal',
                    fontWeight: 400,
                    mt: { xs: '5px', sm: '5px' },
                    textAlign: 'Center',

                  }}>
                    One of the greatest advantages of joining your own exclusive miniverse is access to unique
                    games. It’s a first for metaverse experiences, and you’ll be among the fortunate few to get in
                    from the beginning.
                  </Typography>
                </Grid>

              </Grid>

            </Grid>
          </Grid>


        </Grid>


      </Box>
    </Box>
  );
}

export default GamesNewContainer;
